.addressComponent{
    margin: 2rem 0;
    
    h4{
        margin: 0;
        width: auto;
        justify-content: left !important;
    }
    a{
        display: grid;
        font-weight: 100;
    }
}

@media screen and (max-width: 1024px) {
    .addressComponent{
        margin: 1rem 0;

        h4{
            justify-content: center !important;
        }
    }
}
