.sectionBlogs{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

.sectionLabel{
    font-size: 28px;
    margin: 0 2rem;
}

.featuredSectionBlogs{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .sectionBlogs{
        flex-direction: column;
    }

    .featuredSectionBlogs{
        display: flex;
        flex-direction: column;
    }
}