.contractsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contractsContent{
    margin: 3rem 0;
    max-width: var(--computer-max-width);
    width: 90%;
}

.contractVehicles{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.dropdownBox{
    margin: 2rem 0;
    padding: 1rem 2rem;
    overflow: hidden;
    background-color: #f1f1f1;
    transition: max-height 0.4s ease-out;
}

@media screen and (max-width: 1024px) {
    .contractsDesc{
        text-align: center;
    }

    .dropdownBox{
        padding: 0rem 1rem;
    }
}