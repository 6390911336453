.capabilitiesBlock{
    display: flex;
    justify-content: center;
}

.miniCapabilitiesBlock{
    display: flex;
    justify-content: center;
    align-self: baseline;
}

.capabilitiesComputerDisplay{
    display: flex;
    align-items: center;
    justify-content: center;
}

.capabilitiesContent{
    display: flex;
    max-width: var(--computer-max-width);
    width: 90%;
    margin: 5rem 0rem;
    align-items: center;
}

.miniCapabilitiesContent{
    display: flex;
    align-items: center;
    width: 90%;
}

.textLeft{
    background-color: #f8f9f9;
}

.textRight{
    background-color: white;
}

.blockIcon{
    width: 18rem;
    margin: 0rem 2rem;
}

.miniBlockIcon{
    width: 3.5rem;
    margin: 0rem 1rem;
}

.miniBlockIconFont{
    font-family: "__themify_5";
    font-size: 32px;
    color: #559aff;
}

.capabilitiesText{
    margin: 0rem 2rem;
}

.capabilitiesText h6{
    color: #4C585E;
}

.miniCapabilitiesText{
    margin: 0rem 1rem;
}

.miniCapabilitiesText h4{
    font-size: 17px;
    
}

.miniCapabilitiesTopic{
    font-weight: bold;
    margin-bottom: 0rem;
}

/* Mobile Display */
@media screen and (max-width: 1024px) {
    .capabilitiesContent{
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: 2rem 0;
    }

    .blockIcon{
        width: 9rem;
        margin: 1rem;
    }

    .miniBlockIcon{
        width: 3rem;
    }

    .capabilitiesText{
        margin: 0;
    }

    .capabilitiesRef{
        margin: 1rem 0;
    }

    .miniCapabilitiesContent{
        width: 95%;
    }

    .miniCapabilitiesBlock{
        width: 100%;
    }
}