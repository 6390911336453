.blogTags {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0;
  width: 100%;
  align-items: center;
  border-top: 1px solid #E8EBEC;
}

.blogTopicsTitle {
  font-size: 34px;
  font-weight: 700;
  color: #404040;
  text-align: center;
}

.blogTagsContent{
  display: flex;
  flex-wrap: wrap;
  max-width: 82rem;
  margin: 1rem 0;
}

.tagBox{
  margin: 1.5rem 0.8rem;
}

.tagBoxRef{
  color: #404040;
  background-color: #fff;
  padding: 1.1rem 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid #E8EBEC;
}

.tagBoxRef:hover{
  border: 1px solid #fff;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.1);
}
