.homeContainer{
    display: block;
    width: 100%;
}

.homeSlideshow{
    display: flex;
    width: 100%;
}

.blurbDiv{
    text-align: center;
    justify-content: center;
    display: flex;
    font-family: "azo-sans-web", sans-serif;
    /* font-family: "__ElegantIcons_5"; */
}

.solutionsDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.solutionsBox{
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: var(--computer-max-width);
    width: 100%;
    margin-bottom: 2rem;
}

.shortcutsSection{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.shortcutsGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: var(--computer-max-width);
}

@media screen and (max-width: 1024px){
    .solutionsBox{
        display: block;
    }

    .shortcutsGrid{
        display: block;
        margin-top: 0rem;
        margin-bottom: 2rem;
    }
}
