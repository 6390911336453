.blogEntryContainer{
    display: flex;
    flex-direction: column;
}

.blogEntry{
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 5%;
    background-color: #fff;
    border-radius: 1rem;
    border: 1px solid #E8EBEC;
}

.blogEntry:hover{
    border: 1px solid #E8EBEC;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
}

.blogContent{
    display: flex;
    flex-direction: column;
    justify-content: right;
    padding: 20px;
}

.blogEntryTitle{
    font-size: 21px;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
    color: #404040;
}

.blogThumbnail{
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 1rem 1rem 0 0;
    align-self: center;
}

.blogEntryDate{
    color: #2D72F8;
    font-size: 14px;
    margin: 0;
}

@media screen and (max-width: 1024px) {
    .blogEntryContainer{
        width: 85%;
    }
}