.pageList{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.pageNames{
    margin: 1.5rem 0;
    padding: 0 1.5rem;
    border-left: 1px solid #E8EBEC;
    border-right: 1px solid #E8EBEC;
    width: 220px;
}

.pageLinkRef{
    color: #404040;
    font-weight: bold;
}

.pageLinkRef:hover{
    color: #2D72F8;
}

.pageLinkTitle{
    font-size: 15px !important;
    font-family: azo-sans-web, sans-serif !important;
    margin: 1.1rem 0 !important;
    line-height: 20px !important;
}