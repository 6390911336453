.homeSlideshow{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.slick-dots{
    position: relative !important;
    bottom: 70px !important;
}

.slick-dots li button:before{
    font-size: 20px !important;
    color: white !important;
}

.slick-slider.slick-initialized{
    height: 619px;
}