.popupDisplay{
    height: 310px;
    display: flex;
    border-top: 1px solid #E8EBEC;
    z-index: 100;
    background-color: white;
    width: 100%;
    position: absolute;
}

.popupDisplay p{
    font-size: 15px !important;
    font-family: azo-sans-web, sans-serif !important;
    line-height: 20px !important;
}

.popupDisplay a{
    font-size: 15px;
}

.popupReferences{
    min-height: 2rem;
    width: 12rem;
}

.displayWrapper{
    display: flex;
}