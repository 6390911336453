.quoteWrapper{
    height: 22rem;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.quoteText{
    font-style: italic;
    font-weight: bold;
    margin: 2rem 0rem;
}

.quoteContent{
    max-width: var(--computer-max-width);
    width: 80%;
}

.author{
    font-weight: bold;
    font-size: 17px;
}

/* Mobile Display */
@media screen and (max-width: 1024px) {
    .quoteText{
        font-size: 20px;
        margin: 1.5rem 0rem;
    }
}
