.specificBlogContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogText{
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
}

.blogData{
    margin-top: 4rem;
    max-width: 100rem;
    width: 90%;
}

.blogTitle{
    font-size: 48px;
    font-weight: 700;
}

.postDate, .tagLabels{
    font-size: 15px;
    margin: 0;
}

.tagLabels a{
    font-size: 15px
}

.tagLabels a:hover{
    border: none
}


.blogText{
    max-width: 75rem;
    width: 85%;
}

.blogText img{
    align-self: center;
    max-width: 40rem;
    width: 80%;
}

.blogText a:hover{
    border: none;
}

@media screen and (max-width: 1024px) {
    .blogData{
        margin: 2rem 0;
        text-align: center;
    }
    .blogTitle{
        font-size: 30px;
        line-height: 50px;
    }
}
