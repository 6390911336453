.changePageNumber{
    display: flex;
    align-items: center;
    justify-content: center;
}

.changePageBtn{
    color: #2D72F8;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    margin: 2rem 0.5rem;
}

.changePageBtn:hover{
    cursor: pointer;
    color: white;
    background-color: #2D72F8;
    border-color: #2D72F8;
}

.currentPageBtn{
    color: white;
    background-color: #2D72F8;
    border: 1px solid #2D72F8;
    border-radius: 6px;
    margin: 2rem 0.5rem;
}

.changePageText{
    margin: 0 4px;
    font-weight: 500;
    font-family: monospace;
}

.changePageTextHolder{
    margin: 0 4px;
    font-weight: 500;
    color: transparent;
    font-family: monospace;
}
.changePageHolder {
    border: 1px;
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    margin: 2rem 0.5rem;
}