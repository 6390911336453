.blogPage{
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
}
.blogContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #f5f5f5;
    height: 1000px;
    
}

.blogList{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 80%;
    
    right: 0;
}
.blogPagination{
    
    margin-top: 20px;
    height: 100px;
    width: 35%;
}
.blogItem{
    display: flex;
    flex-direction: column;
    justify-content: right;
 
    width: 60%;
    margin: 10px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blogContent{

    display: flex;
    flex-direction: column;
    justify-content: right;
}

.blogContent h1{
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    color: #404040;
}

.blogContent p{
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: #404040;
}

.blogContent button{
    display: flex;
    text-decoration: none;
    background-color: #2d72f8;
    justify-content: center;
    padding: 0.5rem 2rem;
    width: fit-content;
    border: none;
    margin-top: 20px;
}
.root{
    --blog-max-width: 82rem;
}

.blogPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    width: 100%;
}

.blogEntriesWrapper, .blogSearchResults{
    display: flex;
    flex-direction: column;
    max-width: var(--blog-max-width);
    width: 90%;
}

.recentBlogs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 2rem 0rem;
}

.searchInputDesc{
    margin: 0;
}

.blogSearch{
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    max-width: 70rem;
    width: 80%;
}

.blogSearchInput{
    width: 100%;
    padding: 0.75rem 1.15rem;
    font-size: 20px;
    font-family: azo-sans-web, sans-serif;
    font-style: italic;
    border: 1px solid #525254;
    box-sizing: border-box;
}

.blogSearchInput:focus{
    outline: none;
}

.noResultsWrapper{
    margin-bottom: 2rem;
}

@media screen and (max-width: 1024px){
    .blogList{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .searchInputDesc{
        text-align: center;
    }

}