.slideshowBackground{
    display: flex;
    width: 100%;
    height: 619px;
    background-size: cover;
}

.slideshowComponent{
    width: 45rem;
    height: 100%;

    color: white;
    margin-top: 6rem;
    margin-left: 6rem;
}

.slideshowComponent a{
    color: white;
}

.slideshowComponent h4{
    width: max-content;
}

/* Mobile Display */
@media screen and (max-width: 1024px){
    .slideshowComponent{
        width: 100%;
        margin: 4rem 10%;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .slideshowComponent h1{
        font-size: 42px;
        line-height: 50px;
    }

    .slideshowComponent h4{
        margin: 1rem 0rem;
    }
}