.popupLinks{
    display: flex;
    align-items: center;
}

.linkNames{
    display: flex;
    margin: 0;
    li{     
        button {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            border-style: none;
            color: #576670;
            background-color: white;
            width: 8rem;
            margin-bottom: 0;
        }

        .selected {
            color: #0238A1;
            border-bottom: 5px solid #0238A1;
        }

        button:hover{
            cursor: pointer;
            color: #0238A1
        }
    }
}
