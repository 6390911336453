.categoryName{
    color: #0238A1;
    font-weight: 700;
}

.popupDescription{
    margin-left: 4rem;
    margin-right: 2rem;
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    button{
        background-color: #2d72f8 !important;
    }
}