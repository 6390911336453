.conclusionBanner{
    display: flex;
    flex-direction: column;
    height: 24rem;
    background-size: cover;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;

    button{
        width: inherit;
        margin-top: 3rem;
        margin-bottom: 0rem;
        background-color: #2d72f8;
        color: white;
        padding: 1rem 2rem;
    }
}

.conclusionTitle, .conclusionBody{
    width: 90%;
    max-width: var(--computer-max-width);
}

.conclusionTitle{
    margin: 4px 0;
}

.conclusionBody{
    margin-bottom: 0rem;
    font-size: 22px;
}


/* Mobile Display */
@media screen and (max-width: 1024px) {
    .conclusionBanner{
        .conclusionTitle{
            font-size: 30px;
        }
        .conclusionBody{
            margin: 1rem 0;
            font-size: 17px;
        }

        button{
            margin: 1rem 0;
        }
    }
}
