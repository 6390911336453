@font-face {
    font-family: elegant-icons;
    src: url('../../assets/fonts/eleganticons.eot');
    src: url('../../assets/fonts/eleganticons.woff') format('woff'), url('../../assets/fonts/eleganticons.svg') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: font-awesome;
    src: url('../../assets/fonts/fontawesome-webfont.eot');
    src: url('../../assets/fonts/fontawesome-webfont.woff') format('woff'), url('../../assets/fonts/fontawesome-webfont.svg') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "__themify_5";
    src: url('../../assets/fonts/themify.eot');
    src: url('../../assets/fonts/themify.woff') format('woff'), url('../../assets/fonts/themify.svg') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
	font-family: azo-sans-web;
	src: url(https://use.typekit.net/af/f2ad1f/0000000000000000774e9259/30/l?subset_id=2&fvd=n5&v=3) format("woff2"), url(https://use.typekit.net/af/f2ad1f/0000000000000000774e9259/30/d?subset_id=2&fvd=n5&v=3) format("woff"), url(https://use.typekit.net/af/f2ad1f/0000000000000000774e9259/30/a?subset_id=2&fvd=n5&v=3) format("opentype");
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: auto;
}

@font-face {
	font-family: azo-sans-web;
	src: url(https://use.typekit.net/af/c05e89/0000000000000000774e925c/30/l?subset_id=2&fvd=n9&v=3) format("woff2"), url(https://use.typekit.net/af/c05e89/0000000000000000774e925c/30/d?subset_id=2&fvd=n9&v=3) format("woff"), url(https://use.typekit.net/af/c05e89/0000000000000000774e925c/30/a?subset_id=2&fvd=n9&v=3) format("opentype");
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	font-display: auto;
}

@font-face {
	font-family: azo-sans-web;
	src: url(https://use.typekit.net/af/aa9d10/0000000000000000774e925d/30/l?subset_id=2&fvd=n3&v=3) format("woff2"), url(https://use.typekit.net/af/aa9d10/0000000000000000774e925d/30/d?subset_id=2&fvd=n3&v=3) format("woff"), url(https://use.typekit.net/af/aa9d10/0000000000000000774e925d/30/a?subset_id=2&fvd=n3&v=3) format("opentype");
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	font-display: auto;
}

@font-face {
	font-family: azo-sans-web;
	src: url(https://use.typekit.net/af/12b127/0000000000000000774e9258/30/l?subset_id=2&fvd=n7&v=3) format("woff2"), url(https://use.typekit.net/af/12b127/0000000000000000774e9258/30/d?subset_id=2&fvd=n7&v=3) format("woff"), url(https://use.typekit.net/af/12b127/0000000000000000774e9258/30/a?subset_id=2&fvd=n7&v=3) format("opentype");
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	font-display: auto;
}

@font-face {
	font-family: azo-sans-web;
	src: url(https://use.typekit.net/af/3c175e/0000000000000000774e9260/30/l?subset_id=2&fvd=n4&v=3) format("woff2"), url(https://use.typekit.net/af/3c175e/0000000000000000774e9260/30/d?subset_id=2&fvd=n4&v=3) format("woff"), url(https://use.typekit.net/af/3c175e/0000000000000000774e9260/30/a?subset_id=2&fvd=n4&v=3) format("opentype");
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: auto;
}

:root{
    --computer-max-width: 67rem;
    --blog-max-width: 82rem;

}

p {
    font-size: 18px;
    line-height: 29px;
    font-family: azo-sans-web, sans-serif;
    font-weight: 100;
    font-weight: lighter;
}

h1{
    font-size: 54px;
    line-height: 60px;
    font-weight: 900;
    margin: 1rem 0rem;
    font-family: azo-sans-web, sans-serif;
}

h2{
    font-size: 36px;
    line-height: 35px;
    font-weight: 700;
    margin: 1rem 0;
    font-family: azo-sans-web, sans-serif;

}

h3{
    font-size: 26px;
    font-weight: 700;
    line-height: 47px;
    font-family: azo-sans-web, sans-serif;
}

h4{
    display: flex;
    text-decoration: none;
    justify-content: center;
    width: fit-content;
}

h4 a{
    color: white;
    font-size: 15px;
    padding: 1.15rem 2.25rem;
}

h5{
    font-size: 20px;
    line-height: 46px;
    font-weight: 300;
    font-family: azo-sans-web, sans-serif;

}

h6{
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
    margin: 0rem;
    font-family: azo-sans-web, sans-serif;
}

a{
    text-decoration: none;
    color: #2D72F8;
    font-weight: 500;
    font-size: 17px;
    font-family: azo-sans-web, sans-serif;
}

a:hover{
    border-bottom: 1px solid;
}

.pageWrapper{
    color: #404041;
}

#Light .topicItem{
    color: #4c585e;
}

#Light .titleItem{
    color: #404041;
}

#Dark .topicItem{
    color: #d7dce2;
}

#Dark .titleItem{
    color: white;
}

.paragraphBold{
    font-weight: 700;
}

button {
    font-size: 18px;
    font-weight: 600;
    border: none;
    background-color: #2d72f8;
    cursor: pointer;
    color: white;
    padding: 0.75rem;
    margin-bottom: 1rem;
}

li{
    list-style: none;
}