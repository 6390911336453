.gridArrangement{
    min-height: 440px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.gridBackground{
    background-size: cover;
}
.gridContent{
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: var(--computer-max-width);
    width: 80%;
    justify-self: center;
}

.gridContent h2{
    margin: 0.5rem 0;
}
