.resourceSection{
    margin-top: 3rem;
    margin-bottom: 2rem;
    max-width: var(--computer-max-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.resourcesGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.sectionTitle{
    font-weight: 700;
}

/* Mobile Display */
@media screen and (max-width: 1024px) {
    .resourcesGrid{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}