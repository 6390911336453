.companyValuesGrid{
    border-top: 1px solid rgba(143, 160, 176, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;

    .workWithUsBlocks{
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 3rem;
    }

    
    button{
        margin-bottom: 2rem;
        background-color: #2d72f8;
        color: white;
        padding: 1rem 2rem;
    }
}

.corePrinciples{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: var(--computer-max-width);
    width: 85%;
    margin-bottom: 5rem;
}

.corePrinciples .capabilitiesBlock{
    margin: 0.5rem 0;
}

.employeeBenefits{
    width: 100%;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.benefitsDescription{
    margin: 1.5rem 0;
    max-width: var(--computer-max-width);
    text-align: center;
    width: 90%;
}

.benefitsStatement{
    margin: 2rem 0;
}

.employeeBenefits{
    margin-top: 0rem;
}

.ourClients {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ourClients p {
    font-size: 26px;
    text-align: center;
}
.ourClients .partnerLogo{
    max-width: 120px;
    max-height: 120px;
    width: auto;
    height: auto;
    padding: 25px;
}

.ourClients .partnersBanner{
    padding: 1.5rem 20%;
}

.professionalCertifications{
    background-color: rgba(232,235,236,.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.certificationsContent{
    max-width: var(--computer-max-width);
    width: 85%;
    margin: 2rem 0;
}

.certificationsTitle{
    margin: 1.5rem 0;
}

.certificationDetails{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1024px) {
    .corePrinciples{
        display: flex;
        flex-direction: column;
    }
    .workWithUsBlocks{
        display: block !important;
    }
}