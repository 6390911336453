.timeline-container {
width: 100%;
min-height: 100vh;
background-size: cover;
background-position: center;
background-attachment: fixed;
}

.timeline p {
    margin: 0 auto;
    font-size: 16px;
}

.timeline {
position: relative;
max-width: 1400px;
margin: 0 auto;
padding: 0 0 40px 0;
font-family: azo-sans-web, sans-serif;
font-size: 18px;
font-weight: 300;
}

.timeline-header {
text-align: center;
color: white;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
margin-bottom: 30px;
padding-top: 40px;
font-weight: 400;
font-size: 18px;
}

.timeline-header h1 {
margin: 0;
font-size: 2.5em;
}

.timeline-header h2 {
margin: 10px 0 0;
font-size: 1.8em;
font-weight: 700;
}

.timeline::after {
content: '';
position: absolute;
width: 1.5px;
background-color: rgb(236, 215, 215);
top: 200px;
bottom: 30px;
left: 50%;
margin-left: -2px;
}

.timeline-important {
    font-weight: bold;
}

.timeline-item {
padding: 10px 40px;
position: relative;
width: 50%;
box-sizing: border-box;
color: #FFFFFF;
font-family: azo-sans-web, sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 31px;
text-align: right;
padding: 0px 0px 20px;
}

.timeline-year {
font-family: azo-sans-web, sans-serif;
font-size: 26px;
font-weight: 900;
line-height: 26px;
}

.timeline-item::after {
content: '';
position: absolute;
width: 10px;
height: 10px;
background-color: grey;
border: 2x solid #FF9F55;
top: 38%;
border-radius: 50%;
z-index: 1;
transform: translateY(-50%);
}

.left {
left: 0;
padding-right: 10px;
}

.right {
left: 50%;
padding-left: 10px;
}

.left::after {
right: 1px;
}

.right::after {
left: 1px;
}

.content {
padding: 10px 30px;
position: relative;
border-radius: 6px;
color: white;
text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}

.left .content {
text-align: right;
}

.right .content {
text-align: left;
}

@media screen and (max-width: 1024px) {
.timeline::after {
    left: 31px;
}

.timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
}

.timeline-item::after {
    left: 15px;
}

.right {
    left: 0%;
}

.left .content,
.right .content {
    text-align: left;
}
}