/* Mobile Display */
.blockBackground{
    height: 200px;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.block{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #d7dce2;
}

.blockContent{
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 80%;
}

