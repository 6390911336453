/* Computer Display */
.footerWrapper p{
    line-height: 20px;
    font-size: 14px;
}

.footerWrapper a{
    color: white;
    text-decoration: none;
    font-size: 14px;
}

.footerWrapper a:hover{
    color: #559AFF;
    border: none;
}

.footerDiv{
    background-color: #1B2E3C;

    button{
        background-color: #1B2E3C !important;
    }
    
}

.footerBackground{
    background-color: #1B2E3C;
    width: 100%;
    display: flex;
    justify-content: center;
}

.footerDiv{
    max-width: 100rem;
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    min-height: 441px;
}

.firstColumn, .secondColumn, .thirdColumn, .fourthColumn{
    margin-left: 2%;
    margin-right: 2%;
}

.firstColumn {
    margin-top: 80px;
}

.homeFooterBtn{
    width: 175px;
}

.secondColumn p, .thirdColumn p, .fourthColumn p{
    font-weight: 500;
}

.secondColumn{
    width: 180px;
}

.columnTitle{
    margin-top: 4.5rem;
    padding: 0;
}

.plainTitle{
    margin-top: 20px;
}

.titleRef{
    font-size: 17px !important;
}

.columnChildren{
    display: grid;
}

.columnChild{
    font-size: 14px;
    text-align: left;
    padding: 0;
}

.thirdColumn{
    width: 250px;
}

.fourthColumn{
    width: 130px;
}

.miscSection{
    margin: 1.5rem 0;
}

.socialMediaIcons{
    margin-top: 25px;
}

.socialMediaIcons a{
    margin-right: 10px;
}

.copyrightSection{
    background-color: #233445;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyrightSection p{
    color: white;
    font-weight: 200;
    font-size: 12px;
}

/* Mobile Display */
@media screen and (max-width: 1024px){
    .footerDiv{
        flex-direction: column;
    }
    .logoDiv{
        margin: 20px 0px;
        text-align: center;
    }

    .footerSections{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .columnTitle{
        margin: 8px 10px;
        font-size: 14px !important;
    }

    .miscSection{
        display: flex;
        margin: 0;
    }

    .footerAddresses{
        text-align: center;
    }

    .footerWrapper p{
        font-size: 12px;
    }

    .footerWrapper .contactInfoSection{
        display: flex;
        justify-content: center;
    }

    .columnChildren{
        display: block;

        .columnChild{
            margin: 0.5rem;
        }
    }

    .socialMediaIcons{
        margin-top: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}