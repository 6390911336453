.contactGridWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem 0;
    width: 100%;
}

.contactGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    max-width: 68rem;
}

.contactComponent{
    width: 90%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-self: center;
}

.contactTitle{
    font-weight: 700;
}

.messageForm{
    display: flex;
    flex-direction: column;
}

.messageInput{
    transition: none;
    margin: 0.5rem 0;
    padding: 1.25rem 0.8rem;
    font-size: 15px;
    font-family: azo-sans-web, sans-serif;
}

.messageForm textarea{
    min-height: 170px;
    resize: none;
}

.messageInput, .messageText{
    border: 1px solid #E8EBEC;
}

.messageInput:focus, .messageText:focus{
    border: 1px solid #525254;
    outline: none;
}

#invalidInput{
    border-color: #D7244C;
    color: #D7244C; 
}

#invalidInput::placeholder{
    color: #D7244C; 
}

.messageContactDiv{
    display: flex;
    margin: 1.5rem 0;
}

.messageCheckBox{
    transform: scale(1.5);
    margin-bottom: 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.sendMessage{
    display: flex;
    text-decoration: none;
    background-color: #2d72f8;
    justify-content: center;
    padding: 0.5rem 2rem;
    width: fit-content;
    border: none;
}

.sendMessage:hover{
    cursor: pointer;
    opacity: 0.75;
}

.sendText{
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    color: white;
}

.sendMessage:hover{
    cursor: pointer;
    opacity: 0.75;
}

.messageSentDisplay{
    margin-top: 2rem;
}

.messageSentDisplay p{
    margin: 0;
    font-style: italic;
    font-weight: 700;
}

.mapIFrame{
    width: 100%;
    height: 350px;
    border: solid 1px #404040;
}

.contactUsDiv p{
    font-size: 16px;
    line-height: 24px !important;
}

.contactUsDiv .phoneNumber, .mailText{
    font-size: 14px !important;
    font-weight: 700;
    margin: 0.1rem 0;
}

.contactUsDiv a{
    color: #404040;    
    font-size: 16px;
    font-weight: 500;
}

.contactUsDiv a:hover{
    border:none;
}

@media screen and (max-width: 1024px) {
    .contactGrid{
        display: flex;
        flex-direction: column;
        width: 90%;
    }
}