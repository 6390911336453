.additionalSource{
    margin-left: 2rem;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sourceCategory{
    color: #0238A1;
    margin: 0;
    font-weight: 500;
}

.sourceRef{
    margin: 3rem 0;
}

.sourceRef p{
    line-height: 0px !important;
}