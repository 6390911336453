.featuredClient{
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3.5rem 0rem;
}

.featuredClientContent{
    max-width: var(--computer-max-width);
    display: flex;
    flex-direction: column;
    width: 85%;
}

.featuredClientText{
    font-size: 18px;
}

.departmentSpan{
    font-weight: 400;
}

.linkSection{
    margin: 1rem 0;
}

.blogLink{
    margin-top: 1rem;
    color: white;
    width: max-content;
}

/* Tablet Display */
@media screen and (max-width: 1024px) {
    .featuredClient{
        padding: 3rem 0;
    }

    .featuredClientContent{
        text-align: center;
        align-items: center;
    }

    .featuredClientContent h2{
        font-size: 22px;
    }

    .topicItem{
        margin-bottom: 1rem;
    }
    .department{
        margin-bottom: 2rem;
    }

    .featuredClientText{
        font-size: 17px;
    }

    .linkSection{
        margin-top: 2rem;
    }
}
