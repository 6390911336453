.contractVehicleEntry{
    text-align: center;
    margin: 1rem 1.75rem;
    width: 27%
}

.contractImage{
    vertical-align: middle;
    /* height: inherit; */
    max-width: 150px;
}

.contractTitle{
    margin: 1rem 0;
    font-size: 18px;
    font-weight: 500;
    color: #2D72F8;
}

.contractNumber{
    font-size: 13px;
    line-height: 16px;
    font-weight: 300;
    margin: 0.5rem 0;
}

.learnMoreButton{
    border: none;
    background-color: #2d72f8;
    color: white;
    margin: 0.5rem 0;
}

.learnMoreButton:hover{
    cursor: pointer;
    opacity: 0.75;
}

.buttonText{
    margin: 1rem 0.5rem;
    font-weight: 500;
    font-size: 15px;
    line-height: 0;
}

@media screen and (max-width: 1024px) {
    .contractVehicleEntry{
        width: 80%
    }
}