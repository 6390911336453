.boxWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxedContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    justify-content: center;
    text-align: center;
    margin: 4rem 1%;
}

.boxedContentImg{
    width: 15rem;
}

/* Mobile Display */
@media screen and (max-width: 1024px){
    .boxedContent{
        margin: 2rem 0rem;
    }

    .boxedContentImg{
        width: 10rem; 
    }


}
