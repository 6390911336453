
.pageBlurb{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #404041;
    max-width: var(--computer-max-width);
    width: 85%;
    margin-top: 2rem;
}

.blurbContent{
    font-size: 23px;
}