.hamburgerDisplay{
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;

    ul {
        padding: 0;
        margin: 0;
    }
    
    li:first-child{
        background-color: #09234C;
        
        button{
            font-size: 18px;
            background-color: #09234C;
            color: white;
        }
    }
    
    li{
        background-color: white;
        border-bottom: 1px solid #d7dce2; 
        
        button{
            font-size: 16px;
            border: none;
            background-color: white;
            color: #404041;
            font-weight: 700;
            padding: 1rem 2rem;
            line-height: 25px;
        }
    }
}

.hamburgerLogo{
    width: 166px;
}

.hamburgerTop{
    margin: 2rem 2rem;
    display: flex;
    align-items: center;
}

.exitDiv{
    margin-left: auto;
    display: flex;
    align-items: center;
}

.exitIcon{
    width: 30px;
    filter: invert(33%) sepia(47%) saturate(2725%) hue-rotate(207deg) brightness(102%) contrast(95%);
}

.openIcon{
    width: 30px;
    filter: invert(33%) sepia(47%) saturate(2725%) hue-rotate(207deg) brightness(102%) contrast(95%);
}

.hamburgerRefs{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
}
