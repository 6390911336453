.servicesGrid{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.servicesGridContent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: var(--computer-max-width);
    margin-bottom: 3rem;
}

.servicesReference{
    margin: 1rem 0;
    width: 90%;
    justify-self: center;
}

.serviceComponent{
    margin: 0.5rem 0;
    width: 90%;
    display: flex;
    justify-self: center;
}

/* Mobile Display */
@media screen and (max-width: 1024px) {
    .servicesGridContent{
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        align-items: center;
    }

    .serviceComponent{
        margin: 0.5rem 0;
        display: flex;
    }

    .serviceComponent p{
        font-size: 17px;
    }

    .servicesReference{
        margin: 0;
        margin-bottom: 2rem;
    }
}
