.contactInfoSection{
    .contactInfo{
        display: flex;
        align-items: center;

        a{
            font-weight: 700;
            line-height: 0px !important;
        }
    }
}

@media screen and (max-width: 1024px) {
    .contactInfoSection{
        display: grid !important;

        .contactInfo{
            padding: 0.25rem;
        }
    }
}