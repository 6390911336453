.taggedBlogs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.taggedBlogsList{
    max-width: var(--blog-max-width);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 2rem 0rem;
}

.tagLargeTitle{
    text-align: center;
    font-weight: 700;
    font-size: 48px;
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.semiboldTitle{
    font-weight: 300;
}

@media screen and (max-width: 1024px) {
    .taggedBlogsList{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tagLargeTitle{
        font-size: 36px;
    }
}