.factSheet{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: solid #dde2e7 0.1px;
}

.factSheetText{
    max-width: 60rem;
    width: 80%;
    text-align: center;
    font-size: 19px;
    line-height: 35px;
    font-weight: 200;
}

.capabilitiesBlock .bulletPoints{
    margin: 2rem 0;
}

.capabilitiesBlock .bulletPointText{
    line-height: 25px;
    font-weight: 700;
}

.capabilitiesBlock .bulletPoint{
    font-family: elegant-icons;
}

.bulletPointText{
    margin: 0.5rem 0;
}


/* Mobile Display */
@media screen and (max-width: 1024px){
    .factSheetText{
        width: 80%;
        margin: 0;
    }
}