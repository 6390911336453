.topicWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
}

.topicBlurb{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: end;

    p{
        max-width: 75%;
        margin: 0.5rem;
    }
}

.topicItem{
    text-transform: uppercase;
}

.titleItem{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    text-align: center;
}

.centeredBar{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.horizontalDivider{
    margin-bottom: 2rem;
    background-color: #d7dce2;
    height: 0.3rem;
    width: 2.5rem;
}

