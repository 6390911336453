.titleBanner{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 400px;
    background-size: cover;
    background-color: rgba(60,96,255,.9);
    align-items: center; 
    justify-content: center;   
    text-align: center;
}

.bannerBlurb{
    color: #d7dce2;
    text-transform: uppercase;
}

.bannerText{
    font-size: 4rem;
    font-weight: 900;
    color: white;
    margin: 0.5rem 5rem;
    text-transform: uppercase;
}

/* Mobile Display */
@media screen and (max-width: 1024px) {
    .bannerText{
        font-size: 40px;
    }
}