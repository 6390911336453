/* Browser Display */
.headerContent button{
    font-family: azo-sans-web, sans-serif;
}

.headerLinks{
    display: flex;
    min-height: 65px;
}

.logoLink{
    display: flex;
    position: relative;
    align-items: center;
    width: 166px;
    border-right: 1px solid #E8EBEC;
    padding-right: 28px;
    padding-left: 28px;
}

.logoLink:hover{
    border-bottom: none;
}

.righthandButtonsDisplay{
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

/* Header Mobile Display */
@media screen and (max-width: 1160px){
    .mobileHeader{
        display: flex;
        height: 65px;
        align-items: center;
        width: 100%;
    }

    .logoLink{
        /* width: 180px; */
        border: none;
        padding-right: 0;
    }

    .hamburgerHeader{
        padding: 0px 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #E8EBEC;

        button{
            background-color: white;
            margin: 0;
        }
    }
}