.resourceSections{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 6rem;
}

.socialMediaLinks{
    margin-top: 2.5rem;
    margin-bottom: 0;
}

.socialMediaLinks a{
    margin: 0.75rem;
}

.socialMediaLinks a:hover{
    border: none;
}

.conclusionIcons{
    width: 36px;
}

@media screen and (max-width: 1024px) {
    .resourceLibrary .conclusionTitle{
        font-size: 38px;
    }

    .resourceLibrary .socialMediaLinks{
        margin: 1rem 0;
    }
}