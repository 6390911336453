.smallBlogEntryContainer{
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin: 1rem;
}

.smallBlogEntry{
    border: 1px solid #E8EBEC;
    border-radius: 1rem;
    background-color: #fff;
    padding: 1rem;
    color: #404040;
}

.smallBlogEntry:hover{
    border:1px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.smallBlogEntryTitle{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    color: #404040;
}

.smallBlogEntryDate{
    margin: 0;
    font-size: 14px;
    color: #2D72F8;
}

/* @media screen and (max-width: 1024px) {
    .smallBlogEntryContainer{
        width: 85%;
    }
} */