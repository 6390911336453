.certificationItem{
    margin-bottom: 1rem;
}

.certificationName{
    font-weight: 700;
}

.certificationImages{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.certificationLogo{
    height: 10.5rem;
    margin: 0 8px;
}

@media screen and (max-width: 1024px) {
    .certificationLogo{
        width: 90%;
        height: auto;
    }
}