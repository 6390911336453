.memberBox{
    display: inline-block;
    padding: 1rem;
}

.profileImage{
    width: 100%;
    height: auto
}

.memberContent{
    display: flex; 
    justify-content: space-between;
}

.memberContent p{
    line-height: 1.4rem;
}

.memberName{
    margin-top: 1rem;
    margin-bottom: 0rem;
    font-size: 1.1rem;
    font-weight: bold;
}

.memberTitle{
    margin-top: 0rem;
    font-size: 0.95rem;
    font-weight: 300;
}

.linkedinLink{
    display: flex;
    align-items: center;
}

.linkedinLink:hover{
    border: none;
}

.linkedinLogo{
    width: 100%;
    opacity: 75%;
}
