.resourceEntry{
    border: 1px solid #E8EBEC;
    border-radius: 4px;
    margin: 1rem;
    width: 276px;
    height: 263px;
}

.categoryIcon{
    font-family: elegant-icons;
    margin-top: 5px;
}

.resourceContent{
    margin: 1.65rem 1.5rem;
}

.resourceCategory{
    font-size: 15px;
}

.resourceName{
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
}

.resourceLink{
    display: flex;
    flex-direction: column;
    position: relative;
    height: inherit;
}

.resourceLink:hover{
    color: white;
    background-color: #0E3464;
    border-bottom: none;
    border-radius: 4px;
}

.resourceLink:hover .resourceName, .resourceLink:hover .resourceDesc{
    color: white;
}

.resourceLink:hover .downloadSection{
    background-color: #2d72f8;
}

.resourceName{
    color: #404040;
    margin-bottom: 0.5rem;
}

.resourceDesc{
    font-size: 15px;
    line-height: 20px;
    color: #404040;
    margin: 0;
}

.downloadText{
    color: white;
    font-size: 13px;
    font-weight: 500;
    margin: 10px 24px;
}

.downloadSection{
    background-color: #0E3464;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 4px;
}

@media screen and (max-width: 1024px) {
    .resourceEntry{
        max-width: 276px;
        width: 80%;
    }
}