.contentQuoteBackground{
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.contentQuoteWrapper{
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 75rem;
    margin: 4.5rem 6rem;
}

#Light .sideQuote{
    border-left: 18px #25c4aa solid;
    color: #7d8891;
}

#Dark{
    color: white;
}

#Dark .sideQuote{
    border-left: 18px white solid;
}

.contentQuoteSection{
    display: grid;
    grid-template-columns: 2.25fr 1fr;
}

.contentQuoteText{
    margin-right: 1rem;
}

.contentQuoteText p{
    margin: 1.5rem 0;
}

.sideQuote{
    margin-left: 3rem;
    padding-left: 2rem;
    align-self: center;
    display: flex;
    flex-direction: column;
}

.sideQuote h2{
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-weight: 700;
}

.sideQuoteAuthor {
    font-weight: 500;
}

.sideQuote p{
    margin: 0rem;
}

@media screen and (max-width: 1024px) {
    .contentQuoteSection{
        display: flex;
        flex-direction: column;
    }

    .contentQuoteWrapper{
        width: 80%;
        margin: 3rem 0;
    }

    .sideQuote{
        margin: 0;
        width: 65%;
    }
}