.partnersBanner{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0rem;
    border-bottom: 1px solid #d7dce2;
    flex-wrap: wrap;
}

.partnerLogo{
    height: 44px;
    width: auto;
    padding: 25px;
}

/* Mobile Display */
@media screen and (max-width: 675px) {
    .partnerLogo{
        height: 34px;
        padding: 20px;
    }
}