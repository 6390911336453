
.righthandButtons{
    display: flex;
}

.righthandButtons button{
    display: flex;
    align-items: center;
    text-decoration: none;

    padding: 2px 30px !important;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 0;
    margin-top: 0;
    height: 34px;
    width: 8.875rem !important;
    font-size: 15px;
    font-weight: 600;
}

.resourcesRef{
    color: #2D72F8 !important;
    border: 0.1px solid #2D72F8;
    background-color: white;
}

.resourcesRef:hover{
    background-color: #2D72F8;
    color: white;
    border: 0.1px solid #2D72F8;
}

.contactUsRef{
    color: #25C4AA !important;
    border: 0.1px solid #25C4AA;
    background-color: white;
}

.contactUsRef:hover{
    background-color: #25C4AA;
    color: white;
    border: 0.1px solid #25C4AA;
}