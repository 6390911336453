.centeredMembers{
    display: flex;
    align-items: center;
    justify-content: center;
}

.teamMembers{
    width: 90%;
    max-width: var(--computer-max-width);
    justify-content: center;
    margin-bottom: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

/* Mobile Display */
@media screen and (max-width: 1024px) {
    .teamMembers{
        grid-template-columns: 1fr 1fr;
        text-align: center;
    }
}